<template>
  <section>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h2>Notifications</h2>

          <div class="block my-buttons">
            <p>
              Get alerted when your balance drops below a certain level.
              <span v-if="!notifications.mobile"
                >To get started simply enter your mobile number below.</span
              >
            </p>
            <div class="row">
              <div class="col-md-12">
                <b-form-input
                  size="lg"
                  placeholder="Mobile Number"
                  v-model="notifications.mobile"
                  type="tel"
                ></b-form-input>
              </div>
            </div>
            <div>
              <div class="row">
                <div class="col-2 col-md-1">
                  <b-form-checkbox
                    v-bind:disabled="
                      !notifications.is_minimum_amount || !notifications.mobile
                    "
                    v-model="notifications.is_minimum"
                    switch
                    size="lg"
                  ></b-form-checkbox>
                </div>
                <div class="col-10 col-md-6">
                  <div class="notify-desc">
                    <div class="title">Minimum Balance</div>
                    <div class="content">
                      Notified when your balance goes below your specified
                      minimum amount
                    </div>
                  </div>
                  <div style="margin-top: 10px;">
                    <b-input-group prepend="£">
                      <b-form-input
                        placeholder="Minimum amount"
                        v-bind:disabled="!notifications.mobile"
                        v-model="notifications.is_minimum_amount"
                        type="number"
                        step="0.01"
                        min="0.00"
                      ></b-form-input>
                    </b-input-group>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-2 col-md-1">
                  <b-form-checkbox
                    v-bind:disabled="!notifications.mobile"
                    v-model="notifications.is_negative"
                    switch
                    size="lg"
                  ></b-form-checkbox>
                </div>
                <div class="col-10 col-md-11">
                  <div class="notify-desc">
                    <div class="title">Negative Balance</div>
                    <div class="content">
                      Notified when your balance goes below £0.00
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-2 col-md-1">
                  <b-form-checkbox
                    v-bind:disabled="!notifications.mobile"
                    v-model="notifications.is_cutoff"
                    switch
                    size="lg"
                  ></b-form-checkbox>
                </div>
                <div class="col-10 col-md-11">
                  <div class="notify-desc">
                    <div class="title">Supply Isolated</div>
                    <div class="content">
                      Notified when your supply has been isolated
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-2 col-md-1">
                  <b-form-checkbox
                    v-bind:disabled="!notifications.mobile"
                    v-model="notifications.is_payment"
                    switch
                    size="lg"
                  ></b-form-checkbox>
                </div>
                <div class="col-10 col-md-11">
                  <div class="notify-desc">
                    <div class="title">Payment</div>
                    <div class="content">
                      Notified when you make a successful payment
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-2 col-md-1">
                  <b-form-checkbox
                    v-bind:disabled="!notifications.mobile"
                    v-model="notifications.is_weekly"
                    switch
                    size="lg"
                  ></b-form-checkbox>
                </div>
                <div class="col-10 col-md-6">
                  <div class="notify-desc">
                    <div class="title">Weekly Balance Reminder</div>
                    <div class="content">
                      Notified your balance at a given time every week
                    </div>
                  </div>
                  <div style="margin-top: 10px;">
                    <b-form-select
                      :disabled="!notifications.is_weekly"
                      v-model="notifications.is_weekly_day"
                      :options="days"
                    ></b-form-select>
                    <b-form-select
                      :disabled="!notifications.is_weekly"
                      v-model="notifications.is_weekly_hour"
                      :options="hours"
                    ></b-form-select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <b-button
                    v-on:click="updateNotifications"
                    v-if="!notificationLoading"
                    variant="outline-success"
                    >Update Preferences</b-button
                  >
                  <div v-if="notificationLoading">
                    <b-spinner variant="primary" label="Spinning"></b-spinner>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { faSpinner, faAlignLeft } from "@fortawesome/free-solid-svg-icons";

library.add(fas, faSpinner, faAlignLeft);

export default {
  name: "PrePayNotifications",
  props: {
    id: {
      type: String,
      required: true
    },
    notifications: {
      type: Object,
      required: true
    }
  },
  computed: {
    hours() {
      let hours = [];

      hours.push({ value: null, text: "Please select an hour" });

      let i = 0;
      for (i = 0; i < 24; i++) {
        hours.push({ value: i, text: i + ":" + "00" });
      }

      return hours;
    }
  },
  data() {
    return {
      notificationLoading: false,
      days: [
        { value: null, text: "Please select a day" },
        { value: 1, text: "Monday" },
        { value: 2, text: "Tuesday" },
        { value: 3, text: "Wednesday" },
        { value: 4, text: "Thursday" },
        { value: 5, text: "Friday" },
        { value: 6, text: "Saturday" },
        { value: 0, text: "Sunday" }
      ]
    };
  },
  methods: {
    refreshData() {
      this.$emit("refresh");
    },
    updateNotifications() {
      this.notificationLoading = true;

      const mobile = this.notifications.mobile;
      const isMinimum = this.notifications.is_minimum;
      const isMinimumAmount = this.notifications.is_minimum_amount;
      const isNegative = this.notifications.is_negative;
      const isCutoff = this.notifications.is_cutoff;
      const isPayment = this.notifications.is_payment;
      const isWeekly = this.notifications.is_weekly;
      const isWeeklyDay = this.notifications.is_weekly_day;
      const isWeeklyHour = this.notifications.is_weekly_hour;

      if (isWeekly && (isWeeklyDay === null || isWeeklyHour === null)) {
        this.$swal({
          type: "error",
          title: "Weekly Notification",
          text: "Select an hour and day to be notified"
        });
        this.notificationLoading = false;
        return;
      }

      const id = this.id;

      this.$store
        .dispatch("dashboardNotifications", {
          id,
          mobile,
          isMinimum,
          isMinimumAmount,
          isNegative,
          isCutoff,
          isPayment,
          isWeekly,
          isWeeklyDay,
          isWeeklyHour
        })
        .then(() => {
          this.refreshData();

          const title = "Updated Notification Preferences";
          const subtitle = "Your notification preferences have been updated";
          const samePage = true;
          this.$store.dispatch("pushSuccess", { title, subtitle, samePage });
        })
        .catch(err => {
          this.$swal({
            type: "error",
            title: "Oops...",
            text: err
          });
        })
        .finally(() => {
          this.notificationLoading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "src/scss/dashboard";
</style>
